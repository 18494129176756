const comics = [
  { url: require('@/assets/lesson1/scene1_1.svg') },
  { url: require('@/assets/lesson1/scene1_2.svg') },
  { url: require('@/assets/lesson1/scene1_3.svg') },
  { url: require('@/assets/lesson1/scene2_1.svg') },
  { url: require('@/assets/lesson1/scene2_2.svg') },
  { url: require('@/assets/lesson1/scene2_3.svg') },
  { url: require('@/assets/lesson1/scene3_1.svg') },
  { url: require('@/assets/lesson1/scene3_2.svg') },
]

export default {
  name: 'lesson8',

  setup() {
    return {
      comics,
    }
  },
}
