export const popupsArr1 = [
  { text: 'Ух, как много директорий! Обычно вирусы и троянские программы маскируются под исполняемые файлы.' },
  { text: 'Исполняемый файл — это файл, который внутри себя содержит программу, которую может выполнить компьютер.' },
  { text: 'Например, когда ты пытаешься установить на компьютер игру, первым делом запускаешь тот самый исполняемый файл. Обычно исполняемые файлы имеют расширение (оканчиваются) на .exe. Например: <a>имя_файла.exe.</a> ', key: 'fileExe' },
  {
    text: 'Это значит, что среди множества файлов нужно оставить только файлы с расширением .exe.  Сделай это при помощи команды ', buttonTextTitle: 'dir  *.exe', key: 'btnArr1', tooltip: 'Выводит список всех файлов с расширением .exe. \n\n Вместо * консоль подставляет название файла.',
  },
]

export const popupsArr2 = [
  // { text: 'Время создания файла может стать еще одним индикатором, который может помочь найти вирус.' },
  // { text: 'Мы видим, что у большинства файлов дата создания 1–2 месяца назад, а у нескольких файлов совсем свежая. Это вызывает подозрение, потому что файлы на банкомате появляются не часто.' },
  { text: 'Теперь самое интересное! Как понять, какой исполняемый файл является вирусом?' },
  { text: 'Для этого нам нужно проанализировать код exe-файлов с помощью антивируса.' },
  { text: 'Антивирусную программу также можно запустить при помощи терминала, если она установлена на компьютер.' },
  { text: 'Для этого введи команду:', buttonTextTitle: 'start kaspersky_scan.exe *.exe ', tooltip: 'Запуск антивирусной программы для проверки всех файлов с расширением .exe' },
]

export const popupsArr3 = [
  {
    text: 'Остался только один файл, который имеет подозрительное содержание. \n'
        + 'Антивирус отметил его таким образом: HEUR:Trojan-ATM.Suspicious, где:\n\n'
        + '– HEUR — способ обнаружения вируса (эвристический анализ, который позволяет обнаружить, например, ранее неизвестные угрозы).\n'
        + '– Trojan — тип вируса.\n'
        + '– ATM (от англ.: Automated Teller Machine, ATM (банкомат)) — вирус предназначен для заражения  банкоматов.\n'
        + '– Suspicious — отметка, что файл вызвал подозрение.\n\n'
        + 'Отправлю его на исследование в «Лабораторию Касперского».\n',
    buttonTitle: 'ПРОДОЛЖИТЬ',
    event: 'nextStep',
  },
]

export const code1 = `11.05.2021 10:43              64   DriverExtensions.exe
12.01.2022 08:34              35   WebServer.exe
11.01.2021 09:39     <DIR>         Catacomb
07.02.2021 20:00     <DIR          DriverExtensions
13.04.2021 03:40              128  Application Support.exe
09.05.2021 17:41     <DIR>         Caches
12.06.2021 20:00              64   ColorSync.exe
23.04.2021 19:47     <DIR>         CoreMediaIO
15.06.2021 17:44     <DIR>         Application Support
13.08.2021 20:00     <DIR>         DriverExtensions
16.12.2021 22:44              5   .localized`

export const code2 = `11.06.2021 10:43              64   DriverExtensions.exe
12.01.2022 08:34              35   WebServer.exe
13.03.2021 03:40              128  Application Support.exe
15.04.2021 20:00              64   ColorSync.exe`

export const code3 = `17.02.2021 10:43              64   DriverExtensions.exe
<span style="color: red;">12.01.2022 08:34              35   WebServer.exe (HEUR:Trojan-ATM.Suspicious)</span>
13.07.2021 03:40              128  Application Support.exe
16.08.2021 20:00              64   ColorSync.exe`
