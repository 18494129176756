export const popupsArr = [
  { text: 'Сейчас мы находимся в терминале — это программа, которая позволяет общаться с системой при помощи текстовых команд.' },
  { text: 'В компьютере существует много файлов разного типа: видео, изображения, музыка, документы, программы и другие. Под каким-то из них может скрываться вирус.' },
  { text: 'Все эти файлы распределены по директориям (это такие «папки», созданные системой для хранения файлов).' },
  {
    text: 'Чтобы посмотреть все директории, введи команду:', buttonTextTitle: 'dir', key: 'dir', tooltip: 'Показывает содержимое директории',
  },
]

export const popupsArrError = [
  { text: 'Такой команды не существует! Проверь правильность написания команды.' },
]
