export const popupsArr1 = [
  { text: 'Ух, как много директорий! Обычно вирусы и троянские программы маскируются под исполняемые файлы.' },
  { text: 'Исполняемый файл — это файл, который внутри себя содержит программу, которую может выполнить компьютер.' },
  { text: 'Например, когда ты пытаешься установить на компьютер игру, первым делом запускаешь тот самый исполняемый файл. Обычно исполняемые файлы имеют расширение (оканчиваются) на .exe. Например: <a>имя_файла.exe.</a> ', key: 'fileExe' },
  {
    text: 'Это значит, что среди множества файлов нужно оставить только файлы с расширением .exe. Сделай это при помощи команды:', buttonTextTitle: 'dir  *.exe', key: 'btnArr1', tooltip: 'Выводит список всех файлов с расширением .exe. \n\n Вместо * консоль подставляет название файла.',
  },
]

export const popupsArr2 = [
  { text: 'Теперь самое интересное! Как понять, какой исполняемый файл является вирусом?' },
  { text: 'Для этого нам нужно проанализировать код exe-файлов с помощью антивируса.' },
  { text: 'Антивирусную программу также можно запустить при помощи терминала, если она установлена на компьютер.' },
  { text: 'Для этого введи команду:', buttonTextTitle: 'start kaspersky_scan.exe *.exe', tooltip: 'Запуск антивирусной программы для проверки всех файлов с расширением .exe' },
]

export const popupsArr3 = [
  {
    text: `Остался только один файл, который имеет подозрительное содержание. Антивирус отметил его меткой Suspicious (подозрительный).

      Отправлю его на исследование в «Лабораторию Касперского».`,
    buttonTitle: 'ПРОДОЛЖИТЬ',
    event: 'nextStep',
  },
]

export const popupsArrError = [
  { text: 'Такой команды не существует! Проверь правильность написания команды.' },
]

export const code1 = `10.01.2021 10:43              64   DriverExtensions.exe
12.01.2022 08:34              35   WebServer.exe
10.01.2021 09:39     <DIR>         Catacomb
07.01.2021 20:00     <DIR          DriverExtensions
12.01.2021 03:40              128  Application Support.exe
11.01.2021 17:41     <DIR>         Caches
12.01.2021 20:00              64   ColorSync.exe
04.01.2021 19:47     <DIR>         CoreMediaIO
12.01.2021 17:44     <DIR>         Application Support
12.01.2021 20:00     <DIR>         DriverExtensions
12.01.2021 22:44              5   .localized`

export const code2 = `10.01.2021 10:43              64   DriverExtensions.exe
12.01.2022 08:34              35   WebServer.exe
12.01.2021 03:40              128  Application Support.exe
12.01.2021 20:00              64   ColorSync.exe`

export const code3 = `10.01.2021 10:43              64   DriverExtensions.exe
<span style="color: red;">12.01.2022 08:34              35   WebServer.exe (Suspicious)</span>
12.01.2021 03:40              128  Application Support.exe
12.01.2021 20:00              64   ColorSync.exe`
