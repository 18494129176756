import { ref } from '@vue/composition-api'

import { compareCommand } from '@/utils/compareCommand.js'
import { pushPopup } from '@/utils/pushPopup'
import { popupsArr, popupsArrError } from './arrs'

export default {
  name: 'step2',
  setup(props, ctx) {
    const { emit } = ctx
    const active = ref(true)
    const popups = ref([])
    const consoleInput = ref('')
    const container = ref(false)
    const start = ref(false)

    const init = async () => {
      await pushPopup(popupsArr, popups.value)
      start.value = true
    }

    init()

    const nextStep = () => {
      emit('nextStep', 3)
    }

    const check = () => {
      if (compareCommand(consoleInput.value, 'dir')) {
        active.value = false
        container.value = true
      } else {
        pushPopup(popupsArrError, popups.value)
      }
    }

    return {
      active,
      popups,
      consoleInput,
      container,
      start,
      nextStep,
      check,
    }
  },
}
