import { ref } from '@vue/composition-api'

import step0 from './components/step0'
import step1 from './components/step1'
import step2 from './components/step2'
import step3 from './components/step3'
import step4 from './components/step4'

export default {
  name: 'lesson1',
  components: {
    step0,
    step1,
    step2,
    step3,
    step4,
  },
  setup() {
    const active = ref(false)
    const step = ref(0)

    const nextStep = (val) => {
      step.value = val
    }

    return {
      active,
      step,
      nextStep,
    }
  },
}
