import { ref, computed } from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'
import { compareCommand } from '@/utils/compareCommand.js'

import {
  popupsArr1, popupsArr2, popupsArr3, code1, code2, code3,
} from './arrs'
import { popupsArrError } from '../../../../Education2/Lesson1/components/step3/arrs'

export default {
  name: 'step3',
  setup(props, ctx) {
    const { emit } = ctx
    const active = ref(true)
    const popups = ref([])
    const step = ref(0)

    const iPopup = ref(0)
    const lengthDisabledPopup = ref(0)
    const consoleInput = ref('')
    const steps = ref({
      dir: true,
      dirExe: false,
      startKaspersky: false,
      startKasperskyVirus: false,
    })

    const init = () => {
      pushPopup(popupsArr1, popups.value, iPopup.value)
    }

    init()

    const clearConsoleInput = () => {
      setTimeout(() => {
        consoleInput.value = ''
      }, 100)
    }

    const nextStep = (val) => {
      if (compareCommand(consoleInput.value, 'dir *.exe')) {
        steps.value.dirExe = true
        pushPopup(popupsArr2, popups.value, iPopup.value)
        lengthDisabledPopup.value = 4
        clearConsoleInput()
      } else if (compareCommand(consoleInput.value, 'start kaspersky_scan.exe *.exe')) {
        steps.value.startKaspersky = true
        clearConsoleInput()
      } else {
        pushPopup(popupsArrError, popups.value)
      }

      if (!val) return
      if (val.event === 'nextStep') {
        emit('nextStep', 4)
      }
    }

    const consoleFinish = () => {
      step.value += 1
      if (step.value === 2) {
        lengthDisabledPopup.value = 4
        pushPopup(popupsArr2, popups.value, iPopup.value)
      }
    }

    const loadingFinish = () => {
      steps.value.startKasperskyVirus = true
      pushPopup(popupsArr3, popups.value, iPopup.value)
      lengthDisabledPopup.value = 8
    }

    // watch(consoleInput, () => {
    //   nextStep()
    // })

    const activeConsole = computed(() => steps.value.dirExe && steps.value.startKaspersky)

    return {
      active,
      popups,
      step,
      lengthDisabledPopup,
      steps,
      consoleInput,
      activeConsole,
      code1,
      code2,
      code3,
      nextStep,
      consoleFinish,
      loadingFinish,
    }
  },
}
